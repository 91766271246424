import { Injectable } from '@angular/core';

import { UserTeam } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class UserTeamsService extends GenericObjectsService<UserTeam> {
  protected baseUrl = '/user-teams';
}
